export enum SvgIcon {
  SEARCH = 'search',
  SUPPORT = 'support',
  POLYGON = 'polygon',
  ACTIONS = 'actionsolygon',
  ARROW_BACK = 'arrowBack',
  ADD = 'add',
  CLOSE = 'closeDialog',
  EYE = 'eye',
  CHECKED = 'checked',
  DELETE = 'deleteIcon',
  MORE = 'more',
  DOWNLOAD = 'download',
  FILE = 'file',
  EDIT = 'edit',
  PAGINATION_ARROW = 'paginationArrow',
  BURGER = 'burger',
  SAVE = 'save',
  EMBLEM = 'emblem',
  LOGO_WHITE = 'logoWhite',
  HI = 'hi',
  HERALD = 'herald',
  PEN_LOGO = 'penLogo',
  NAV_ARROW = 'navArrow',
  TELEGRAM = 'telegram',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  FB = 'fb',
  INFO = 'info',
  PLUS = 'plus',
  MINUS = 'minus',
  MAP = 'map',
  PHONE = 'phone',
  MAIL = 'mail',
  QUESTION = 'question',
  LINK_ARROW = 'linkArrow',
  SIGN = 'sign',
  CONTRAST = 'contrast',
  PLANET = 'planet',
}
